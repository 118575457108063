<template>
  <div>
    <b-tooltip
      target="warningCertificadoVencido"
      placement="bottom"
    >
      Atenção! Certificado vencido. Clique aqui para atualizar.
    </b-tooltip>
    <font-awesome-icon
      v-if="hasCertificadoVencido"
      id="warningCertificadoVencido"
      class="warning-certificado"
      :icon="['fas', 'triangle-exclamation']"
      fade
      size="lg"
      @click="$router.push({ name: 'certificado-view', query: { filtroRapido: 'Vencidos' } })"
    />
  </div>
</template>

<style lang="scss">
.warning-certificado {
  background-color: #f20d0d;
  padding: 8px;
  border-radius: 50%;
  color: yellow;
}
</style>

<script>
/* eslint-disable no-param-reassign */
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import api from '@/app/certificado-digital/shared/services/api'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  setup() {
    const hasCertificadoVencido = ref([])

    const buscarCertificadoVencido = async () => {
      hasCertificadoVencido.value = (await api.getCertificadoVencido()).data
    }

    buscarCertificadoVencido()

    EventBus.$on('atualizaCertificadosVencidos', () => buscarCertificadoVencido())

    return {
      hasCertificadoVencido,
    }
  },
}
</script>
